@import 'src/sass/button.module';


.termsText {
  display:     block;
  text-align:  right;
  font-size:   12rem;
  max-width:   400rem;
  margin:      0 0 24rem auto;
  line-height: 18rem;

  span {
    margin:      0 4rem;
    font-weight: bold;
  }

  a {
    font-size: inherit;
    display:   inline-block;
    margin:    0 0 0 4rem;
  }
}
