//GREYSCALE
$BLACK: #000;
$DARK_GREY: #333;
$MED_GREY: #555;
$LIGHT_GREY:      #5A5A5A;
$WHITE:           #FFF;

$OFF_WHITE:       #F8F9FA;
$DARK_WHITE:      #F0F0F0;
$AVG_GREY:        #CCC;
$QKN_RED:         #C20631;
$ERROR_RED:       #FF5959;
$QKN_ERROR_RED:   #c60c30;

$SIMP_H_COLOR:    #3C3D67;
$DARK_PURPLE:     #35354D;
$AZURE:           #147CC6;
$AZURE_ACTIVE:    #105b92;
$PURPLE:          #2C2889;
$LIGHT_PURPLE:    #8268FF;
$PURPLE_SOFT:     #363FFF;
$LANDING_TEXT:    #9D9CAE;
$ORANGE:          rgba(239, 135, 100, 1);
$HEADING_COLOR:   #35364D;
$SECONDARY_COLOR: #9D9CAE;

$LINK: rgba(53, 68, 245, 1);
$LINK_ACTIVE: rgba(42, 43, 132, 1);
$LINK_VISITED: rgba(125, 108, 246, 1);
$SUCCESS_COLOR: rgba(104, 211, 171, 1);

$SIMP_BTN: rgba(55, 62, 255, 1);
$SIMP_BTN_ACTIVE: rgba(44, 40, 137, 1);
$SIMP_BTN_DISABLED: #717294;

$QKN_BG_COLOR: $DARK_WHITE;
$QKN_HEADER_COLOR: $DARK_GREY;
$QKN_FOOTER_COLOR: #666;
$QKN_BORDER_COLOR: $AVG_GREY;
$QKN_INPUT_ERROR_BG: #FeF5F1;
$QKN_INPUT_ERROR_COLOR: #8C2E0B;
$QKN_INPUT_SUCCESS_BG: #EAF5E7;
$QKN_INPUT_SUCCESS_BORDER_COLOR: #2BAD3E;
