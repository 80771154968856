@import 'src/sass/qkn-themes.module';


.qknSelectField {
  width:         100%;
  border-radius: 4rem;
  padding:       4rem 16rem 4rem 4rem;
  border:        solid 1rem $QKN_BORDER_COLOR;
  font-size:     14rem;
  color:         $MED_GREY;
  margin-bottom: 4rem;

  &.error {border: solid 1rem $ERROR_RED;}
}
