@import 'src/sass/button.module';
@import 'src/sass/qkn-themes.module';
@import 'src/sass/text-areas.module';

.qknActivationBox {
  composes: quickenBox;
  text-align: left;
  margin: 0 auto 24rem;
  align-items: flex-start;
  padding-top: 40rem;
  label { font-size: 16rem;}
}

.codeInput {
  composes: qknTextInput;
  margin: 8rem 0 16rem;
  width: 280rem;
}

.descriptionArea {
  margin-top: 24rem;
  font-size: 14rem;
  line-height: 20rem;

  button {
    width: 288rem;
    margin-top: 32rem;
  }

  @media(min-width: $QKN_MOBILE) {
    display: flex;

    button {
      margin-top: unset;
      margin-left: 32rem;
    }
  }
}
