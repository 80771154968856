@import 'src/sass/button.module';
@import 'src/sass/qkn-themes.module';


.successBanner {
  display:          flex;
  align-items:      center;
  color:            $DARK_GREY;
  width:            90%;
  margin:           0 auto 16rem;
  border:           1rem solid $QKN_INPUT_SUCCESS_BORDER_COLOR;
  background-color: $QKN_INPUT_SUCCESS_BG;
  border-radius:    4rem;
  padding:          8rem;

  span {
    font-weight: bold;
  }

  img {
    margin-right: 8rem;
  }

  p {
    font-size: 12rem;
  }

  @media(min-width: $QKN_MOBILE) {
    width: $QKN_MAIN_AREA;
  }
}


.successArea {
  padding-bottom: 64rem;

  h1 {
    font-size: 32rem;

    @media(max-width: 680px) {
      margin-left: 5%;
      font-size: 32rem;
      width: 450rem;
    }

    @media(min-width: $QKN_MOBILE) {
      margin-left: auto;
      font-size: 35rem;
      width: $QKN_MAIN_AREA;
    }
  }
}


.successBox {
  composes:       quickenBox;
  font-family:    $OPEN_SANS;
  margin:         0 auto;
  text-align:     center;
  align-items:    center;
  padding-bottom: 48rem;

  h2 {
    font-size:   20rem;
    font-weight: 500;
    margin-bottom: 8rem;
    span {
      display: block;
      @media(min-width: $QKN_MOBILE) {
        display: inline;
        &:first-child { margin-right: 4rem;}
      }
    }
  }

  .instructions {
    margin:      1rem auto 20rem;
    color:       $LIGHT_GREY;
    font-size:   16rem;
    font-weight: 100;

    & p:first-child {
      margin-bottom: 4rem;
    }
  }
}


.visitArea {
  font-size:   16rem;
  composes:    quickenBox;
  margin:      24rem auto 0;
  text-align:  center;
  font-weight: 100;
  color:       $LIGHT_GREY;
  line-height: 20rem;
}


.myAccountLink {
  composes:    qknLinkBtn;
  display:     inline-block;
  margin:      0 4rem;
  font-size:   16rem;
  font-weight: 100;
}
