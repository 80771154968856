@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/button.module';
@import 'src/sass/text-areas.module';
@import 'src/sass/simp-themes.module';


.banner {
  flex-direction: column-reverse;

  p { font-weight: bold;}

  svg {
    height:     72rem;
    width:      72rem;
    margin-top: 16rem;
  }
}

.successHeading {
  composes:    h1Heading;
  font-family: $CIRCULAR_MEDIUM;
  max-width:   272rem;
  margin:      40rem auto;
  color:       $DARK_PURPLE;
  font-weight: normal;
  font-size:   24rem;
  line-height: 32rem;

  @media(min-width: $SIMP_DESKTOP) {
    max-width:   620rem;
    font-size:   32rem;
    line-height: 36rem;
  }
}


.confirmArea {
  text-align:  center;
  font-family: $CIRCULAR_BOOK;

  h2 {
    font-size:   16rem;
    color:       $SECONDARY_COLOR;
    font-family: $CIRCULAR_BOOK;
    font-weight: lighter;
    max-width:   240rem;
    margin:      0 auto 10rem;

    span {font-weight: bold}

    @media(min-width: $SIMP_DESKTOP) {
      font-size: 24rem;
      max-width: 100%;
    }
  }

  .descriptionArea {
    color:       $SECONDARY_COLOR;
    font-weight: lighter;
    font-size:   16rem;
    width:       350rem;
    margin:      0 auto 80rem;

    @media(min-width: $SIMP_DESKTOP) {
      width: 100%;
    }
  }

  .offerArea {
    margin-top:     40rem;
    display:        flex;
    flex-direction: column;
    align-items:    center;

    @media(min-width: $SIMP_DESKTOP) {
      flex-direction:  row;
      justify-content: space-around;
      align-items:     flex-start;
    }
  }

  button {
    margin: 10rem auto;
  }
}
