@import 'themes/colors';
@import 'themes/sizes';
@import 'themes/fonts';
@import 'button.module';

.qknH1 {
  font-family: $OPEN_SANS;
  font-weight: 200;
  font-size: 32rem;
  margin: 32rem auto 28rem;
  color: $QKN_HEADER_COLOR;
  width: 90%;

  @media(min-width: $QKN_MOBILE) {
    font-size: 36rem;
    width: $QKN_MAIN_AREA;
  }
}

.quickenBox {
  width: 90%;
  background-color: $WHITE;
  color: $DARK_GREY;
  font-family: $OPEN_SANS;
  border: 1rem solid #ccc;
  border-radius: 4rem;
  display: flex;
  flex-direction: column;
  padding: 24rem;

  @media(min-width: $QKN_MOBILE) {
    width: $QKN_MAIN_AREA;
  }
}

.qknLabel {
  color:         $DARK_GREY;
  margin-bottom: 8rem;
  font-weight:   700;
  display:       block;
  font-size:     14rem;
  font-family:   $OPEN_SANS;
}

.qknError {
  font-size: 12rem;
  color:     $ERROR_RED;
}

.winDownLoad {
  composes: qknBtn;
  margin: 16rem 0 20rem;
  width: 240rem;
}

.macDownLoad {
  display: flex;
  font-size: 14rem;
  color: $LIGHT_GREY;
  font-weight: 100;

  a {
    margin-left: 4rem;
    font-weight: 600;
  }
}
