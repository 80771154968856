@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/text-areas.module';


.paypalRow {
  padding:        32rem 16rem 16rem;
  border-left:    solid 1rem $QKN_BORDER_COLOR;
  border-right:   solid 1rem $QKN_BORDER_COLOR;
  border-bottom:  solid 1rem $QKN_BORDER_COLOR;
  border-radius:  0 0 4rem 4rem;
  display:        flex;
  flex-direction: column;

  @media(min-width: $SIMP_DESKTOP) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap:          20rem;
    grid-column-gap:       40rem;
    grid-template-areas:
      'description ppButton'
      'ppName ppZip';
  }
}


.paypalArea {
  grid-area: ppButton;

  p {
    text-align: left;
    font-size:  12rem;
    color:      $ERROR_RED;
    height:     14rem;
    margin-top: 4rem;
  }
}


.description {
  color:        $HEADING_COLOR;
  font-family:  $CIRCULAR_BOOK;
  font-size:    14rem;
  text-align:   left;
  grid-area:    description;
  margin-bottom: 20rem;

  @media(min-width: $SIMP_DESKTOP) {
    margin-bottom: 0;
    margin-right: 15rem;
  }
}


.nameField {grid-area: ppName;}


.zipField {
  grid-area: ppZip;
  width:     50%;
}


.nameField,
.zipField {
  display:        flex;
  flex-direction: column;

  label {
    font-family:   $CIRCULAR_MEDIUM;
    margin-bottom: 5rem;
    font-size:     14rem;
    color:         $DARK_PURPLE;
    text-align: left;
  }

  input {
    height: 35rem;

    &.error {
      border: solid 2rem $ERROR_RED;
    }
  }

  p {
    text-align: left;
    font-size:  12rem;
    color:      $ERROR_RED;
    height:     14rem;
    margin-top: 4rem;
  }
}


