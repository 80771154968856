@import 'src/sass/themes/pallet';

html, body {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}


body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  display: block;
}

button {
  border: 0;
  cursor: pointer;
  background-color: transparent;
}

img {
  display: block;
  pointer-events: none;
}

h1,h2,h3,h4,h5,h6,p,label {
  margin: 0;
  padding: 0;
  //-webkit-font-smoothing: antialiased;
}
