@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';
@import 'src/sass/themes/fonts';
@import 'src/sass/button.module';
@import 'src/sass/text-areas.module';

.mobileBox {
  width: 350rem;
  font-family: $CIRCULAR_BOOK;
  background-color: $WHITE;
  box-shadow: 0 0 16rem -6rem #9D9CAE;
  border-radius: 28rem;
  font-size: 17rem;
  font-weight: lighter;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &:after {
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    height: 23rem;
    background-color: $LIGHT_PURPLE;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  h3 {
    color:       $LIGHT_PURPLE;
    font-family: $CIRCULAR_BOOK;
    font-size:   20rem;
    text-align:  center;
    width:       260rem;
    margin:      0 auto 24rem;
    padding-top: 48rem;
  }

  p {
    font-family: $CIRCULAR_BOOK;
    width: 210rem;
    margin: 0 auto 40rem;
    font-size: 14rem;
    color: $SECONDARY_COLOR;
    text-align: center;
    line-height: 20rem;
  }

  svg {
    margin-bottom: 16rem;
    flex: 1 0;
  }

  a {
    margin: 0 auto 47rem;;
    background-color: $LIGHT_PURPLE;
  }
}
