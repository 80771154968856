@import 'src/sass/themes/sizes';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'page-layout.module';


.simpPageWrapper {
  padding:        16rem;
  margin:         0 auto;
  display:        flex;
  flex-direction: column;
  position:       relative;
  z-index:        1;
  flex:           1;

  @media(min-width: $SIMP_TABLET) {
    padding: 24rem 32rem;
  }

  @media(min-width: $SIMP_DESKTOP) {
    padding: 32rem 0;
    width:   $SIMP_DESKTOP;
  }
}


.simpError {
  font-size: 12rem;
  color:     $ERROR_RED;
}


.h1Heading {
  color:       $HEADING_COLOR;
  font-family: $CIRCULAR_MEDIUM;
  font-size:   24rem;
  text-align:  center;

  @media(min-width: $SIMP_TABLET) {
    font-size: 28rem;
  }
  @media(min-width: $SIMP_DESKTOP) {
    font-size: 30rem;
  }
}


.canvas {
  position:       fixed;
  z-index:        0;
  display:        flex;
  flex-direction: column;
  width:          100%;
  height:         100%;

  div:first-child {
    flex: 0 1 650rem;
  }

  div:last-child {
    flex:              1 0;
    background-repeat: repeat-x;;
    background-image:  url('https://www.simplifimoney.com/assets/images/simplifi_dotpat_square.svg');
  }
}
