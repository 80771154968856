@import 'src/sass/button.module';

.terms {
  span {
    font-weight: bold;
    margin-left: 4rem;
  }

  a {
    margin: 0 4rem;
    display: inline-block
  }
}
