@import 'src/sass/button.module';
@import 'src/sass/qkn-themes.module';

.subscribedArea {
  padding-bottom: 80rem;
}

%box {
  composes: quickenBox;
  text-align: center;
  align-items: center;
  color: $DARK_GREY;

  p {font-size: 14rem;}
}

.downloadBox {
  @extend %box;
  margin: 0 auto 16rem;
  padding-bottom: 48rem;
}

.purchaseBox {
  @extend %box;
  margin: 0 auto;
  padding-bottom: 32rem;
}

.qknH2 {
  font-family: $OPEN_SANS;
  font-size: 22rem;
  font-weight: 400;
  margin-bottom: 20rem;
  color: $QKN_HEADER_COLOR;
}

.productBtn {
  composes: qknBtn;
  width: 260rem;
}
