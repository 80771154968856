@import 'src/sass/button.module';
@import 'src/sass/qkn-themes.module';


.qknActivationArea {
  padding-bottom: 72rem;
}


.qknH3 {
  font-size:   16rem;
  font-weight: normal;
  font-family: $OPEN_SANS;
}


.qknBuyBox {
  composes:    quickenBox;
  align-items: flex-start;
  margin:      0 auto;

  h2 {margin-bottom: 8rem;}
  a {font-weight: bold;}
}
