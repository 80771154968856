@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/button.module';
@import 'src/sass/text-areas.module';

.downloadWrapper {
  display: block;
  margin-bottom: 64rem;

  & > p {
    margin-top: 10rem;
    font-family: $CIRCULAR_BOOK;
    font-size: 15rem;
    color: $LANDING_TEXT;
    text-align: center;
  }

  @media(min-width: $SIMP_DESKTOP) {
    margin-bottom: 0;
  }
}

.downloadBox {
  width: 330rem;
  height: 400rem;
  font-family: $CIRCULAR_BOOK;
  background-color: $WHITE;
  box-shadow: 0 0 16rem -6rem #9D9CAE;
  border-radius: 28rem;
  font-size: 17rem;
  font-weight: lighter;
  position: relative;

  &:after {
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    height: 23rem;
    background-color: $PURPLE;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }

  .description {
    font-family: $CIRCULAR_BOOK;
    width: 210rem;
    margin: 0 auto 40rem;
    font-size: 17rem;
    color: $SECONDARY_COLOR;
    text-align: center;
  }

  h3 {
    font-size: 20rem;
    color: $PURPLE;
    text-align: center;
    width: 260rem;
    margin: 0 auto 40rem;
    font-weight: bold;
    padding-top: 48rem;
  }


  button {
    background-color: $PURPLE;
  }

  .optionalText {
    font-size: 12rem;
    color: $LANDING_TEXT;
    letter-spacing: .4rem;
  }
}

.codeInput {
  text-align: center;
  padding: 12rem 32rem;
  margin-bottom: 16rem;
  border: solid 1rem $QKN_BORDER_COLOR;
  border-radius: 4rem;
  font-family: $OPEN_SANS;
}

.msgFail {
  font-size: 14rem;
  height: 16rem;
  color: $ERROR_RED;
}

.submitBtn {
  composes: blueBtn;
  &.isSuccess {
    background-color: $SUCCESS_COLOR;
    color: $LINK_ACTIVE;
    cursor: not-allowed;
  }
}
