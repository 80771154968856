@import 'src/sass/themes/sizes';

.logoArea {
  flex: 0 1;
  display: flex;
  margin: 0 auto 44rem;
  justify-content: center;
  align-items: center;

  .otherLogo {
    margin-left: 32rem;
  }

  img {
    height: 40rem;

    @media(min-width: $SIMP_TABLET) {
      height: 60rem;
    }
  }
}
