@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';


.qknFooter {
  flex:             1 0;
  border-top:       1rem solid $AVG_GREY;
  background-color: $WHITE;
  color:            $QKN_FOOTER_COLOR;
  font-size:        12rem;

  .logo {
    width:         114rem;
    height:        28rem;
    margin-bottom: 32rem;
  }
}


.footerWrapper {
  max-width: $QKN_FULL_PAGE;
  padding:   32rem 20rem;
  margin:    0 auto;

  nav {
    margin-bottom: 12rem;

    ul {
      display:   flex;
      flex-wrap: wrap;

      li {
        margin-left:  4rem;
        border-left:  solid 1rem $QKN_FOOTER_COLOR;
        padding-left: 4rem;

        &:first-child {
          border-left:  0;
          padding-left: 0;
        }
      }
    }

    a {
      color: $QKN_FOOTER_COLOR;

      &:hover {
        color:           $AZURE;
        text-decoration: underline;
      }
    }
  }
}
