@import 'src/sass/button.module';
@import 'src/sass/themes/fonts';
@import 'src/sass/simp-themes.module';


.subscribed {
  font-family:    $CIRCULAR_BOOK;
  margin:         24rem auto 0;
  max-width:      330rem;
  display:        flex;
  flex-direction: column;

  h1 {
    margin-bottom: 32rem;
  }

  h2 {
    font-size:   20rem;
    color:       $SECONDARY_COLOR;
    font-family: $CIRCULAR_BOOK;
    text-align:  center;
    width:       360rem;
    margin:      0 auto 60rem;
    font-weight: normal;
  }

  @media(min-width: $SIMP_DESKTOP) {
    max-width: 570rem;
  }
}


.simpHelp {
  text-align: center;
  font-size:  18rem;
  color:      $HEADING_COLOR;

  a {
    display:     inline;
    margin-left: 5rem;
  }
}
