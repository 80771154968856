@import 'src/sass/themes/colors';
@import 'src/sass/button.module';

.activationErr {
  color: $QKN_ERROR_RED;
  font-size: 14rem;
  margin-bottom: 12rem;
  display: inline-block;
  line-height: 20rem;

  a {
    display: inline;
    margin-right: 4rem;
  }
}


.activation2Err {
  color: $QKN_ERROR_RED;
  font-size: 14rem;
  margin-bottom: 12rem;
  display: inline-block;
  line-height: 20rem;
  a {
    display: inline;
    margin-left: 4rem;
  }
}
