@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';

.banner {
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  font-size: 16rem;
  margin-bottom: 16rem;
  font-family: $CIRCULAR_BOOK;

  &.success {color: $SUCCESS_COLOR;}
  &.fail {color: $ERROR_RED;}

  svg { margin-right: 5rem;}
}
