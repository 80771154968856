@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';


.blueBtn {
  background-color: $SIMP_BTN;
  color:            white;
  width:            215rem;
  height:           45rem;
  border-radius:    28rem;
  display:          flex;
  align-items:      center;
  justify-content:  center;
  font-size:        16rem;
  font-family:      $CIRCULAR_BOOK;
  outline:          none;

  &:hover {
    background-color: $SIMP_BTN_ACTIVE;
  }

  &:disabled {
    background-color: $SIMP_BTN_DISABLED;
  }
}


.lightPurpleBtn {
  background-color: $LIGHT_PURPLE;
  color:            white;
  width:            215rem;
  height:           55rem;
  border-radius:    28rem;
  display:          flex;
  align-items:      center;
  justify-content:  center;
  font-size:        1rem;

  &:hover {
    background-color: $SIMP_BTN;
  }

  &:disabled {
    background-color: $SIMP_BTN_DISABLED;
  }
}


.qknBtn {
  background-color: $AZURE;
  color:            $WHITE;
  height:           50rem;
  max-width:        280rem;
  border:           1rem solid #CCC;
  display:          flex;
  justify-content:  center;
  align-items:      center;
  border-radius:    4rem;
  font-size:        14rem;

  &:hover {
    background-color: $AZURE_ACTIVE;
    text-decoration:  underline;
  }

  &:focus {
    outline: rgb(0, 95, 204);
  }

  &:disabled {
    background-color: $DARK_WHITE;
    color:            $AVG_GREY;
    cursor:           not-allowed;

    &:hover {
      text-decoration: none;
    }
  }
}


.linkBtn {
  background-color: transparent;
  border:           0;
  outline:          none;
  color:            $SIMP_BTN;
  font-family:      $CIRCULAR_BOOK;
  font-size:        16rem;

  &:hover {
    text-decoration: underline;
    color:           $LINK_ACTIVE;
  }
}


.qknLinkBtn {
  color:       $AZURE;
  font-size:   14rem;
  font-weight: 300;

  &:hover {
    text-decoration: underline;
    color:           $SIMP_BTN_ACTIVE;
  }
}
