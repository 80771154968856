@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/button.module';
@import 'src/sass/qkn-themes.module';


.autoRenewForm {
  margin-top:  16rem;
  padding-top: 16rem;
  border-top:  solid 1rem $QKN_BORDER_COLOR;
}


.paypal {
  display:         flex;
  justify-content: space-between;
  align-items:     center;
  border-bottom:   solid 1rem $QKN_BORDER_COLOR;
  padding-bottom:  16rem;
  margin-bottom:   16rem;

  & :global(#paypal-button) {
    width:       160rem;
    padding-top: 4rem;
    margin:      0;

    div {
      height: inherit !important;
    }
  }

  @media(min-width: $QKN_MOBILE) {
  }
}


.heading {
  font-size:   20rem;
  font-weight: 600;
}


.creditHeader {
  width:            100%;
  border:           solid 1rem $QKN_BORDER_COLOR;
  background-color: $QKN_BG_COLOR;
  border-radius:    4rem;
  padding:          4rem;
  margin:           16rem 0 8rem;
}


%brainTreeInput {
  height:        34rem;
  border:        solid 1px $QKN_BORDER_COLOR;
  border-radius: 4rem;
  padding:       4rem;

  &.error {border: solid .125rem $QKN_ERROR_RED;}
}


.inputArea {
  display:         grid;
  margin:          16rem 0;
  padding-bottom:  16rem;
  border-bottom:   solid 1rem $QKN_BORDER_COLOR;
  grid-column-gap: 32rem;
  grid-row-gap:    16rem;
  grid-template:
    'ccNumber ccNumber'
    'ccDate ccCvv'
    'ccName ccName'
    'ccPostal blank';

  label {
    color:         $DARK_GREY;
    margin-bottom: 8rem;
    font-weight:   600;
    display:       block;
    font-size:     14rem;
    font-family:   $OPEN_SANS;

    & ~ div {
      @extend %brainTreeInput;

      &:global(.braintree-hosted-fields-invalid) {
        border: solid 2rem $QKN_ERROR_RED;
      }

      &:global(.hasError) {
        border: solid 2rem $QKN_ERROR_RED;
      }
    }
  }


  .ccFieldNum {grid-area: ccNumber;}

  .ccFieldDate {grid-area: ccDate;}

  .ccFieldName {grid-area: ccName;}

  .ccFieldPostal {grid-area: ccPostal;}

  .ccFieldCvv {
    position:  relative;
    grid-area: ccCvv;
  }

  @media(min-width: $QKN_MOBILE) {
    grid-template:
    'ccNumber ccNumber ccDate ccCvv'
    'ccName ccName ccName ccPostal';
  }
}


.cvvHelpBtn {
  composes:  qknLinkBtn;
  font-size: 12rem;
  outline:   none;
}


.helpText {
  position:         absolute;
  background-color: rgba(0, 0, 0, .9);
  color:            $WHITE;
  padding:          20rem;
  border-radius:    4rem;
  width:            280rem;
  font-size:        14rem;
  font-family:      $OPEN_SANS;
  font-weight:      400;
  line-height:      20rem;
  bottom:           84rem;
  left:             -88rem;
  z-index:          100;

  &::after {
    content:      ' ';
    width:        0;
    height:       0;
    position:     absolute;
    bottom:       -8rem;
    right:        120rem;
    border-left:  8rem solid transparent;
    border-right: 8rem solid transparent;
    border-top:   8rem solid rgba(0, 0, 0, .9);
  }
}


.actionRow {
  max-width:       400rem;
  display:         flex;
  margin:          16rem 0 0 auto;
  justify-content: space-between;
}


.submitARBtn {
  composes: qknBtn;
  width:    200rem;
}
