@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';


.simpTextInput {
  font-family:      $HELVETICA;
  text-align:       left;
  display:          block;
  padding:          4rem 8rem;
  border-radius:    6rem;
  border:           1rem solid $QKN_BORDER_COLOR;
  background-color: transparent;
  outline:          none;

  /* Change the white to any color ;) */
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $WHITE inset !important;
  }
}


.qknTextInput {
  font-family:   $OPEN_SANS;
  text-align:    left;
  display:       block;
  padding:       14rem 10rem;
  border-radius: 4rem;
  border:        1rem solid $QKN_BORDER_COLOR;
  font-size:     14rem;
  outline:       none;


  &.error {
    color:      $QKN_INPUT_ERROR_COLOR;
    border:     1rem solid $QKN_ERROR_RED;
    background: $QKN_INPUT_ERROR_BG url('https://quicken.com/sites/all/themes/custom/quicken_2014/images/svg/care-icon--warning.svg') no-repeat right 5rem center;
  }

  &.success {
    border:     1rem solid $QKN_INPUT_SUCCESS_BORDER_COLOR;
    background: $QKN_INPUT_SUCCESS_BG url('https://quicken.com/sites/all/themes/custom/quicken_2014/images/svg/icon-input-checkmark.svg') no-repeat right 5rem center;
    /* Change the white to any color ;) */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px $QKN_INPUT_SUCCESS_BG inset !important;
    }
  }
}
