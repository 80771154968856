@import 'src/sass/themes/sizes';
@import 'src/sass/button.module';

.simpTerms {
  max-width: 352rem;
  display: block;
  margin: 40rem auto 0;
  text-align: center;
  font-size: 16rem;
  line-height: 22rem;
  color: $DARK_PURPLE;

  a {
    display: inline;
    margin: 0 5rem;
  }
  span {
    margin: 0 5rem;
    font-weight: bold;
  }

  @media(min-width: $SIMP_DESKTOP) {
    max-width: 410rem;
  }
}
