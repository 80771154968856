@import 'src/sass/themes/colors';
@import 'src/sass/button.module';

.showBtn {
  composes: qknLinkBtn;
  align-self: flex-start;
  padding: 0;
}


.qknAutoRenewHelp {
  border:           solid 1rem $QKN_BORDER_COLOR;
  background-color: $DARK_WHITE;
  border-radius:    4rem;
  text-align:       left;
  padding:          20rem 24rem 16rem;

  img {
    height:       144rem;
    width:        144rem;
    margin-right: 12rem;
  }

  .headingText {
    margin-bottom: 8rem;
    font-size:     18rem;
    font-weight:   300;
  }

  .wrap {
    font-size:      12rem;
    padding-bottom: 20rem;
    margin-bottom:  16rem;
    border-bottom:  solid 1rem $QKN_BORDER_COLOR;

    p {
      font-size:     12rem;
      margin-bottom: 0;

      span {
        font-weight: bold;
      }
    }

    li {
      display:     flex;
      align-items: center;
      margin:      8rem 0;
    }

    img {
      width:  20rem;
      height: 20rem;
    }
  }

  button {
    font-weight: bold;
  }
}


.helpBtn {
  composes:   qknLinkBtn;
  width:      100%;
  text-align: left;
  padding:    0 0 8rem;
}
