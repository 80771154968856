@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';


.qknHeader {
  width:            100%;
  flex:             0 1;
  padding:          20rem;
  background-color: $WHITE;
  box-shadow:       0 4rem 10rem 0 rgba(0, 0, 0, 0.12);

  @media(min-width: $QKN_FULL_PAGE) {
    padding: 24rem;
  }

  img {
    margin: 0;
    width:  94rem;
    height: 22rem;

    @media(min-width: $QKN_FULL_PAGE) {
      margin: 0 auto;
      width:  150rem;
      height: 35rem;
    }
  }
}
