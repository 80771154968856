@import 'src/sass/button.module';
@import 'src/sass/page-layout.module';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/simp-themes.module';


.topPadding {
  @media(min-width: $SIMP_TABLET) {
    padding-top: 51rem;
  }

  @media(min-width: $SIMP_DESKTOP) {
    padding-top: 128rem;
  }
}

.landingArea {
  display:        flex;
  flex-direction: column;
  align-items:    center;

  .description {
    width:       320rem;
    font-size:   16rem;
    font-family: $CIRCULAR_BOOK;
    color:       $SECONDARY_COLOR;
    margin:      0 auto 48rem;
    line-height: 28rem;
    text-align:  center;

    span {
      color:       $ORANGE;
      font-weight: bold;
    }

    @media(min-width: $SIMP_TABLET) {font-size: 20rem;}
    @media(min-width: $SIMP_DESKTOP) {width: 544rem;}
  }

  a {text-align: center;}
}


.genericHeading {
  composes:      h1Heading;
  margin-bottom: 30rem;
}


.eduH1 {
  composes:      h1Heading;
  font-family:   $CIRCULAR_BOOK;
  font-weight:   normal;
  margin-bottom: 6rem;

  @media(min-width: $SIMP_TABLET) {
    max-width: 300rem;
  }
  @media(min-width: $SIMP_DESKTOP) {
    max-width: 100%;
  }
}


.eduH2 {
  font-size:     24rem;
  font-family:   $CIRCULAR_BOLD;
  color:         $HEADING_COLOR;
  text-align:    center;
  margin-bottom: 40rem;
  max-width:     304rem;

  @media(min-width: $SIMP_TABLET) {
    font-size: 28rem;
    max-width: 400rem;
  }
  @media(min-width: $SIMP_DESKTOP) {
    max-width: 100%;
  }
}


.logInBtn {
  composes:  blueBtn;
  font-size: 16rem;
  margin:    0 auto 32rem;
  width:     192rem;
  height:    48rem;

  @media(min-width: $SIMP_TABLET) {
    margin:    0 auto 48rem;
    font-size: 16rem;
  }
}


.aboutLink {
  composes: linkBtn;
  color:    #373EFF;
}
