@import 'src/sass/button.module';
@import 'src/sass/themes/fonts';
@import 'src/sass/themes/sizes';
@import 'src/sass/text-areas.module';
@import 'src/sass/simp-themes.module';


.simpActivationPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $CIRCULAR_BOOK;
  padding-top: 50rem;

  h1 {
    max-width: 440rem;
    margin-bottom: 32rem;
    font-family: $CIRCULAR_BOOK;
    @media(min-width: $SIMP_DESKTOP) {max-width: 100%;}
  }

  button {margin: 0 auto;}
}

.codeInput {
  composes: qknTextInput;
  margin: 0 auto 32rem;
  text-align: center;
  width: 100%;
  letter-spacing: 1rem;
  font-family: $OPEN_SANS;
  height: 60rem;
  font-size: 22rem;

  @media(min-width: $SIMP_TABLET) {max-width: 440rem;}
}

.submitBtn {
  composes: blueBtn;
  width: 245rem;
  height: 55rem;
}

.errorMsg {
  text-align: center;
  margin-bottom: 16rem;

  p, a {
    font-size: 12rem;
    @media(min-width: $SIMP_TABLET) {font-size: 14rem;}
    @media(min-width: $SIMP_DESKTOP) {font-size: 16rem;}
  }
}
