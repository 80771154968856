@import 'src/sass/themes/fonts';
@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';

.loginPage {
  flex: 1 0;
  font-size: 16rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;

  img {
    margin: 20rem auto 80rem;
    height: 80rem;
  }

  h1 {
    font-family: $CIRCULAR_BOOK;
    color: $DARK_PURPLE;
    flex: 0 1 120rem;
    text-align: center;
    width: 350rem;
    margin-bottom: 20rem;

    @media(min-width: $SIMP_TABLET) {
      margin-bottom: 0;
      width: 540rem;
    }
  }

  iframe {
    flex: 1 0 auto;
    border: 0;
    width: 375rem;
    height: 650rem;
    border-radius: 4rem;
  }
}
