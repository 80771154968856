@import 'src/sass/qkn-themes.module';


.qknInputField {
  width:         100%;
  border-radius: 4rem;
  padding:       7rem 16rem 7rem 4rem;
  border:        solid .0625rem $QKN_BORDER_COLOR;
  font-size:     14rem;
  color:         $MED_GREY;
  margin-bottom: 4rem;

  &.error {border: solid 2rem $ERROR_RED;}
}
