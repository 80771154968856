.qknLogin {
  padding-top: 40rem;
  background-color: white;

  iframe {
    width: 100%;
    border: 0;
    height: 600rem;
  }
}
