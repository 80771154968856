@import 'src/sass/button.module';
@import 'src/sass/qkn-themes.module';


.profileArea {
  padding-bottom: 64rem;
}


.profileBox {
  composes: quickenBox;
  margin:   0 auto;

  h2 {
    font-size:   22rem;
    font-weight: 500;
  }
}


.purchaseInfo {
  margin: 32rem 0;

  & > div {
    &:first-child {margin-bottom: 16rem;}
  }

  @media(min-width: $QKN_MOBILE) {
    display:         flex;
    justify-content: space-between;

    & > div {
      flex: 1 0;

      &:first-child {
        margin-right:  16rem;
        margin-bottom: unset
      }

      &:last-child {margin-left: 16rem;}
    }
  }
}


.profileInfo {
  padding-top:    16rem;
  border-top:     solid 1rem $QKN_BORDER_COLOR;
  display:        flex;
  flex-direction: column;

  & > div {
    margin-bottom: 16rem;
  }

  @media(min-width: $QKN_MOBILE) {
    display:         grid;
    grid-column-gap: 32rem;
    grid-row-gap:    32rem;
    grid-template:
            'fName fName fName lName lName  lName'
            'line1 line1 line1 line2 line2  line2'
            'city  city  state state postal postal';
    & > div {
      margin-bottom: unset;
    }
  }
}


.fNameField {grid-area: fName;}
.lNameField {grid-area: lName;}
.line1Field {grid-area: line1;}
.cityField {grid-area: city;}
.stateField {grid-area: state;}
.postalField {grid-area: postal;}
.line2Field {
  grid-area: line2;

  label {
    color:       transparent;
    user-select: none;
  }
}


.submitBtn {
  composes: qknBtn;
  margin:   32rem 0 32rem auto;
  width:    128rem;
}
