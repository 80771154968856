@import 'src/sass/themes/colors';
@import 'src/sass/themes/sizes';


.paymentLabel {
  display:        flex;
  border:         solid 1rem $QKN_BORDER_COLOR;
  border-radius:  3rem;
  padding:        8rem 16rem;
  margin-top:     20rem;
  cursor:         pointer;
  align-items:    center;
  flex-direction: row;

  div {
    display:     flex;
    flex:        1 0;
    color:       $HEADING_COLOR;
    align-items: center;
    font-size:   14rem;

    @media(min-width: $SIMP_DESKTOP) {
      font-size: 16rem;
    }

    input {
      flex:         0 1 10rem;
      margin-right: 5rem;
    }
  }

  img {
    height: 20rem;

    @media(min-width: $SIMP_DESKTOP) {
      height: 30rem;
    }
  }
}
