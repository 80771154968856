@import 'src/sass/themes/colors';
@import 'src/sass/button.module';


.qknActivationHelp {
  border:        solid 1rem $QKN_BORDER_COLOR;
  border-radius: 4rem;
  text-align:    left;
  padding:       20rem 28rem 16rem 24rem;

  img {
    height:       144rem;
    width:        144rem;
    margin-right: 12rem;
  }

  .headingText {
    margin-bottom: 8rem;
    font-size:     14rem;
    font-weight:   600;
  }

  .wrap {
    display:        flex;
    font-size:      12rem;
    padding-bottom: 20rem;
    margin-bottom:  16rem;
    border-bottom:  solid 1rem $QKN_BORDER_COLOR;

    p.label {
      font-size: 12rem;
      font-weight: bold;
      margin-bottom: 0;
    }

    p {
      line-height:   16rem;
      margin-bottom: 8rem;
    }
  }
}


.helpBtn {
  composes:      qknLinkBtn;
  width:         100%;
  text-align:    left;
  padding:       0 0 8rem;
  border-bottom: solid 1rem $QKN_BORDER_COLOR;
}
