@import 'src/sass/themes/colors';
@import 'src/sass/themes/fonts';
@import 'src/sass/button.module';
@import 'src/sass/simp-themes.module';


.autoRenewArea {
  text-align:     center;
  font-family:    $CIRCULAR_BOOK;
  display:        flex;
  flex-direction: column;
  align-items:    center;

  h1 {
    color:         $HEADING_COLOR;
    margin-bottom: 24rem;

    @media(min-width: $SIMP_TABLET) {
      margin-bottom: 40rem;
      max-width:     320rem;
    }

    @media(min-width: $SIMP_DESKTOP) {
      max-width: 100%
    }
  }

  form {
    margin-top: 16rem;
    width:      100%;

    @media(min-width: $SIMP_TABLET) {
      margin-top: 32rem;
    }
  }
}


.line {
  max-width:   240rem;
  font-size:   16rem;
  color:       $SECONDARY_COLOR;
  text-align:  center;
  margin:      0 auto;
  line-height: 24rem;

  span {
    font-weight: bold;
  }

  @media(min-width: $SIMP_TABLET) {
    font-size: 18rem;
  }

  @media(min-width: $SIMP_DESKTOP) {
    max-width: 400rem;
  }
}


.line1 {
  @extend .line;
  max-width:     300rem;
  margin-bottom: 8rem;

  @media(min-width: $SIMP_TABLET) {
    margin-bottom: 20rem;
  }
}


%brainTreeInput {
  height:        34rem;
  border:        solid 1rem $QKN_BORDER_COLOR;
  border-radius: 4rem;
  padding:       8rem;

  &.error {border: solid 2rem $ERROR_RED;}
}


.inputArea {
  border-left:     solid 1rem $QKN_BORDER_COLOR;
  border-right:    solid 1rem $QKN_BORDER_COLOR;
  border-bottom:   solid 1rem $QKN_BORDER_COLOR;
  border-radius:   0 0 4rem 4rem;
  padding:         32rem 16rem 16rem;
  text-align:      left;
  display:         grid;
  grid-column-gap: 32rem;
  grid-row-gap:    16rem;
  grid-template:
      'ccName ccName'
      'ccNumber ccNumber'
      'ccDate ccCvv'
      'ccPostal blank';

  @media(min-width: $SIMP_DESKTOP) {
    grid-template:
      'ccNumber ccNumber ccDate ccCvv'
      'ccName ccName ccName ccPostal';
  }

  label {
    color:         $DARK_PURPLE;
    margin-bottom: 8rem;
    display:       block;
    font-size:     14rem;
    font-family:   $CIRCULAR_MEDIUM;

    & ~ div {
      @extend %brainTreeInput;

      &:global(.braintree-hosted-fields-invalid) {
        border: solid 2rem $ERROR_RED;
      }

      &:global(.hasError) {
        border: solid 2rem $ERROR_RED;
      }
    }
  }


  .ccFieldNum {grid-area: ccNumber;}

  .ccFieldDate {grid-area: ccDate;}

  .ccFieldName {grid-area: ccName;}

  .ccFieldPostal {grid-area: ccPostal;}

  .ccFieldCvv {
    position:  relative;
    grid-area: ccCvv;
  }
}


.submitBtn {
  composes: blueBtn;
  margin-top: 40rem;
  width:      200rem;
}


.paymentChoice {
  padding:    10rem;
  font-size:  16rem;
  margin-top: 20rem;
  width:      100%;

  @media(min-width: $SIMP_TABLET) {
    width: 360rem;
  }

  @media(min-width: $SIMP_DESKTOP) {
    width: 600rem;
  }
}

.errorMsg {
  text-align: center;
  margin-bottom: 0;
  margin-top: 30rem;
  height: 20rem;
  color: $ERROR_RED;

  p, a {
    font-size: 12rem;
    @media(min-width: $SIMP_TABLET) {font-size: 14rem;}
    @media(min-width: $SIMP_DESKTOP) {font-size: 16rem;}
  }
}
