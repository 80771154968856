@import 'themes/colors';

.pageLayout {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $QKN_BG_COLOR;

  &.light {
    background-color: $WHITE;
  }
}
