@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;

    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
  url($font-file+'.woff') format('woff'),
  url($font-file+'.ttf') format('truetype'),
  url($font-file+'.svg#'+$font-family) format('svg');
    font-weight: normal;
    font-style: normal;
  }
}


$HELVETICA: Helvetica,Arial,sans-serif;
$CIRCULAR_BLACK: 'circularBlack',Arial;
$CIRCULAR_BOLD: 'circularBold',Arial;
$CIRCULAR_BOOK: 'circularBook',Arial;
$CIRCULAR_MEDIUM: 'circularMedium',Arial;

$OPEN_SANS: 'Open Sans', sans-serif;
