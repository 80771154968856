@import 'sass/libs/normalize';
@import 'sass/resets/element-resets';
@import 'sass/themes/fonts';

/*
  Initialization styles, resets, and normalizations
*/

@include font('circularBlack', '../../fonts/circular-black');
@include font('circularBold', '../../fonts/circular-bold');
@include font('circularBook', '../../fonts/circular-book');
@include font('circularMedium', '../../fonts/circular-medium');

html {
  font-size: 1px;
}

#root {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  min-height: 100vh;
}
