@import 'src/sass/qkn-themes.module';
@import 'src/sass/button.module';
@import 'src/sass/themes/fonts';

.autoRenewArea {
  padding-bottom: 64rem;
}

.qknOfferBox {
  composes: quickenBox;
  padding: 10rem;
  margin: 20rem auto;
  flex-direction: row;
  align-items: center;

  img {
    margin-right: 8rem;
  }

  h1 {
    font-family: $OPEN_SANS;
    font-size: 21rem;
    text-transform: uppercase;
    font-weight: normal;
    text-align: left;
  }

  h2 {
    font-family: $OPEN_SANS;
    font-size: 14rem;
    margin-top: 10rem;
    font-weight: normal;
    text-align: left;
  }
}


.autoRenewBox {
  composes: quickenBox;
  margin: 0 auto;
}

.recommendation {
  text-align: left;
  line-height: 14rem;
  font-size: 12rem;
  margin: 0 auto 10rem;

  span {
    font-weight: bold;
    margin-left: 4rem;
  }

  & p:last-child {
    margin-top: 8rem;
  }
}
